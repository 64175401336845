import Choices from 'choices.js';

function initializeChoices() {
    const choicesElements = document.querySelectorAll('.js-choices');
    [...choicesElements].map(el => new Choices(el, {
        classNames: {
            containerInner: 'choices__inner custom-select custom-select-sm rounded bg-white',
        },
    }));
}

export { initializeChoices as default, initializeChoices };
