import Pikaday from 'pikaday';
import Moment from 'moment';
import BookAChefGoogleMap from '../components/maps/BookAChefGoogleMap';
import { DisableFormOnSubmit } from '../components/forms/disableFormOnSubmit';

class BookAChef {
    constructor() {
        DisableFormOnSubmit('#book-a-chef');
        this.hours = null;
        this.demoType = null;
        this.initializeDatePickers();
        this.bindDemoTypeInputEventListeners();
        this.bindHoursInputEventListeners();
        const map = new BookAChefGoogleMap();
        map.init();
    }

    bindDemoTypeInputEventListeners() {
        [...document.querySelectorAll('.js-demo-type-input')].forEach((element) => {
            if (element.checked) {
                this.demoType = element.value;
            }
            element.addEventListener('change', async () => {
                this.demoType = element.value;
                await this.getDefaultHours();
                await this.getPrice();
            });
        });
    }

    bindHoursInputEventListeners() {
        const hoursInputEl = document.querySelector('.js-hours-trigger');
        this.hours = hoursInputEl.value;

        hoursInputEl.addEventListener('change', async () => {
            this.hours = hoursInputEl.value;
            await this.getPrice();
        });
    }

    async getPrice() {
        if (this.demoType && this.hours) {
            fetch(`get-price/${this.demoType}/${this.hours}`)
                .then(res => res.text())
                .then((res) => {
                    document.querySelector('.js-book-price').innerHTML = `${res}`;
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                });
        }
    }

    async getDefaultHours() {
        if (this.demoType) {
            return fetch(`default-hours/${this.demoType}`)
                .then(res => res.text())
                .then((res) => {
                    this.hours = res;
                    const durationSelectElement = document.querySelector('.js-hours-trigger');
                    durationSelectElement.innerHTML = '';

                    // max is always 10
                    // eslint-disable-next-line no-plusplus
                    for (let i = this.hours; i <= 10; i++) {
                        const option = document.createElement('option');
                        option.value = i;
                        option.innerHTML = i;
                        durationSelectElement.appendChild(option);
                    }

                    durationSelectElement.value = `${res}`;
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                });
        }
        return false;
    }

    initializeDatePickers() {
        window.blockedDays = Object.values(window.blockedDays);

        const el = document.querySelector('.js-pikaday');
        el.addEventListener('change', () => el.dispatchEvent(new InputEvent('input')));
        const minDate = new Date();
        minDate.setDate(minDate.getDate() + 14);
        const pickADay = new Pikaday({
            field: el,
            format: 'DD-MM-YYYY',
            formatStrict: 'DD-MM-YYYY',
            minDate,
            onSelect: (date) => {
                el.value = Moment(date).format('DD-MM-YYYY');
            },
            parse: (date) => {
                el.value = Moment(date).format('DD-MM-YYYY');
            },
            disableDayFn: (date) => {
                const formattedDate = [
                    date.getFullYear(),
                    (`0${date.getMonth() + 1}`).slice(-2),
                    (`0${date.getDate()}`).slice(-2),
                ].join('-');

                // check if the date is in blockedDays
                return window.blockedDays.some(e => e.date === formattedDate);
            },
            onDraw: () => {
                window.blockedDays.forEach((blockedDay) => {
                    const date = new Date(blockedDay.date);
                    const day = date.getDate();
                    const month = date.getMonth();
                    const year = date.getFullYear();
                    const dayButton = document.querySelector(`[data-pika-day='${day}'][data-pika-month='${month}'][data-pika-year='${year}']`);
                    if (dayButton) {
                        if (blockedDay.type === 'blocked') {
                            dayButton.classList.add('day-status-blocked');
                        } else if (blockedDay.type === 'busy') {
                            dayButton.classList.add('day-status-busy');
                        }
                    }
                });
            },
        });

        return pickADay;
    }

    dateToDMY(date) {
        return [
            (`0${date.getDate()}`).slice(-2),
            (`0${date.getMonth() + 1}`).slice(-2),
            date.getFullYear(),
        ].join('-');
    }
}

export default BookAChef;
