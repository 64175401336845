import { initializeChoices } from '../components/dropdowns/Choices';
import { initializeDatePickers } from '../components/inputs/DatePicker';

class WarrantyOverview {
    constructor() {
        initializeDatePickers();
        initializeChoices();
    }
}

export default WarrantyOverview;
