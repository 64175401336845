<template>
    <div class="toaster">
        <svg fill="currentColor" viewBox="0 0 20 20" class="toaster__icon">
            <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
            ></path>
        </svg>
        <div class="toaster__content">
            <p class="toaster__title">{{ title }}</p>
            <div class="toaster__body">{{ body }}</div>
        </div>
        <svg fill="currentColor" viewBox="0 0 20 20" class="toaster__close" @click="$emit('close')">
            <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
            ></path>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        body: String,
    },

    mounted() {
        setTimeout(() => this.$emit('close'), 5000);
    }
}
</script>
