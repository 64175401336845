import Pikaday from 'pikaday';

function initializeDatePickers() {
    const inputElements = document.querySelectorAll('.js-pikaday');
    [...inputElements].map((el) => {
        el.addEventListener('change', () => el.dispatchEvent(new InputEvent('input')));
        return new Pikaday({
            field: el,
            format: 'D-M-YYYY',
            onSelect: (date) => {
                // eslint-disable-next-line no-param-reassign
                el.value = [
                    (`0${date.getDate()}`).slice(-2),
                    (`0${date.getMonth() + 1}`).slice(-2),
                    date.getFullYear(),
                ].join('-');
            },
        });
    });
}

export { initializeDatePickers as default, initializeDatePickers };
