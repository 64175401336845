<template>
    <li class="d-flex border-bottom border-light p-2 cursor-pointer">
        <span class="flex-shrink-0 radio" :class="{ 'radio--active': selected }">
            <i class="icon-check text-white" v-if="selected"></i>
        </span>
        <div class="ml-2 flex-grow-1">
            <p class="mb-0">
                <strong>{{ addressName }}</strong>
            </p>
            <span v-if="!editable">{{ fullAddress }}</span>
            <checkout-shipping-address-form-fields
                v-if="selected"
                class="mt-1"
                :address="address"
                :editable="editable"
            ></checkout-shipping-address-form-fields>
        </div>
    </li>
</template>

<script>
import CheckoutShippingAddressFormFields from './CheckoutShippingAddressFormFields.vue';

export default {
    name: 'address-list-item',

    components: {
        CheckoutShippingAddressFormFields,
    },

    props: {
        address: {
            type: Object,
            required: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            required: true,
        },
        addressName: {
            type: String,
            default: '',
        }
    },

    computed: {
        fullAddress() {
            const { address, city, post_code, country_region_code } = this.address;
            return `${address}, ${city} ${post_code} ${country_region_code}`;
        },
    },
};
</script>
