import MicroModal from 'micromodal';
import { SearchDropdown } from '../components/dropdowns/SearchDropdown';
import { Dropdown } from '../components/dropdowns/Dropdown';
import { initializeDatePickers } from '../components/inputs/DatePicker';
import { initializeAutoSizeElements } from '../utils/autoSize';

class WarrantyCreate {
    constructor() {
        this.initializeSearchDropdown();
        this.initializeWarrantyStatusDropdown();
        this.initializeNewItemDropdowns();
        this.initializeDatePickers();
        this.initializeModals();
        this.initializeAutoSizeElements();
    }

    initializeSearchDropdown() {
        const inputEl = document.querySelector('.js-dropdown-trigger');
        const dropdownEl = document.querySelector('.js-dropdown');
        const dropdown = new SearchDropdown(inputEl, dropdownEl); // eslint-disable-line no-new
        window.livewire.on('searchedForItems', () => {
            dropdown.open();
        });
    }

    initializeWarrantyStatusDropdown() {
        const triggerEl = document.querySelector('.js-dropdown-status-trigger');
        const dropdownEl = document.querySelector('.js-dropdown-status');
        if (triggerEl && dropdownEl) {
            new Dropdown(triggerEl, dropdownEl); // eslint-disable-line no-new
        }
    }

    initializeNewItemDropdowns() {
        const dropdownElements = document.querySelectorAll('.js-dropdown-new-item');
        const dropdowns = [...dropdownElements].map((dropdownEl) => {
            const triggerEl = dropdownEl.querySelector('.js-dropdown-new-item-trigger');
            return new SearchDropdown(triggerEl, dropdownEl);
        });
        window.livewire.on('searchedForNewItems', (itemId) => {
            dropdowns
                // eslint-disable-next-line eqeqeq
                .find(dropdown => dropdown.dropdownEl.dataset.id == itemId)
                .open();
        });
    }

    initializeDatePickers() {
        initializeDatePickers();
        // The is_sold property on the Warranty instance renders conditional data.
        // We should re-initialize the datepickers after the dom has changed.
        window.addEventListener('warranty-is-sold-updated', (event) => {
            if (event.detail.value) {
                initializeDatePickers();
            }
        });
    }

    initializeModals() {
        MicroModal.init({
            openClass: 'modal--open',
        });
    }

    initializeAutoSizeElements() {
        // The is_sold property on the Warranty instance renders conditional data.
        // We should re-initialize the auto-sizing elements after the dom has changed.
        window.addEventListener('warranty-is-sold-updated', () => {
            initializeAutoSizeElements();
        });
    }
}

export default WarrantyCreate;
