<template>
    <div class="col-12 mt-1">
        <div class="card card-data bg-light">
            <div class="card-header py-2 px-3">
                <div class="row align-items-center">
                    <div class="col">
                        <h3 class="fs-18 mb-0">{{ trans('checkout.order_summary.title') }}</h3>
                    </div>
                </div>
            </div>
            <div v-if="loadingShippingCosts" class="linear-loading__wrapper">
                <div class="linear-loading__track"></div>
                <div class="linear-loading__indicator linear-loading__indicator--inc"></div>
                <div class="linear-loading__indicator linear-loading__indicator--dec"></div>
            </div>
            <div class="card-body">
                <ul class="list-group list-group-minimal">
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        {{ trans('checkout.order_summary.subtotal') }}
                        <span>{{ formattedSubTotalCost }}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        {{ trans('checkout.order_summary.shipping') }}
                        <span>{{ formattedShippingCost }}</span>
                    </li>
                    <li v-for="(shipmentCostLine, index) of shipmentCostLines"
                        class="list-group-item d-flex justify-content-between align-items-center">
                        <small>{{ shipmentCostLine.description }}</small>
                        <small><span>{{ shipmentCostLine.discountPrice }}</span></small>
                    </li>
                </ul>
            </div>
            <div class="card-footer py-2">
                <ul class="list-group list-group-minimal">
                    <li
                        class="list-group-item d-flex justify-content-between align-items-center text-dark fs-18"
                    >
                        <div>
                            {{ trans('checkout.order_summary.total') }}
                            <p class="small text-black-50">
                                {{ trans('checkout.order_summary.vat_notice') }}
                            </p>
                        </div>
                        <span>{{ formattedTotalCost }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'order-summary',

    props: {
        initialformattedsubtotalcost: String,
        initialformattedshippingcost: String,
        initialformattedtotalcost: String,
        cart: Object,
        initialshipmentcostlines: Array,
    },

    data() {
        return {
            shipmentCostLines: this.initialshipmentcostlines,
            formattedSubTotalCost: this.initialformattedsubtotalcost,
            formattedShippingCost: this.initialformattedshippingcost,
            formattedTotalCost: this.initialformattedtotalcost,
            loadingShippingCosts: false,
        }
    },

    computed: {
        address() {
            return this.$store.getters['address/selectedAddress']
        }
    },

    mounted() {
        this.$watch('address', (newAddress) => {
            this.updateShippingLines(newAddress.address_type);
        });
    },

    methods: {
        updateShippingLines(addressTypeValue) {
            this.loadingShippingCosts = true;
            let url = route('json.checkout.shipping.lines', {addressType: addressTypeValue}).toString();
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    this.formattedShippingCost = data.formattedShippingCost;
                    this.formattedTotalCost = data.formattedTotalCost;
                    this.shipmentCostLines = data.shipmentLines;
                }).finally(() => {
                this.loadingShippingCosts = false;
            });
        }
    }

}
</script>
