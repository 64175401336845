import Vue from 'vue';
import Vuex from 'vuex';
import AddressModule from './modules/address';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        address: AddressModule,
    },
});

export default store;
