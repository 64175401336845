function initializeMultiDropdown() {
    const options = document.getElementsByClassName('js-multi-dropdown-option');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < options.length; i++) {
        options[i].onclick = (e) => {
            e.stopPropagation();
        };
    }
}

export { initializeMultiDropdown as default, initializeMultiDropdown };
