/**
 * @param {HTMLElement} list
 * @param {number} showCount
 */
export class CollapseList {
    constructor(list, showCount = 5) {
        this.list = list;
        this.showCount = showCount;
        this.queryElements();
        this.setActionButtonVisibility();
        this.hideExpandableItems();
        this.bindListeners();
    }

    queryElements() {
        this.listItems = this.list.querySelectorAll('.js-collapse-list-item');
        this.expandEl = this.list.querySelector('.js-collapse-list-expand');
        this.collapseEl = this.list.querySelector('.js-collapse-list-collapse');
    }

    setActionButtonVisibility() {
        const itemCount = [...this.listItems].length;
        this.collapseEl.style.display = 'none';
        if (this.showCount >= itemCount) {
            this.expandEl.style.display = 'none';
        }
    }

    hideExpandableItems() {
        this.setDisplayPropertyForExpandableItems('none');
    }

    showExpandableItems() {
        this.setDisplayPropertyForExpandableItems('block');
    }

    setDisplayPropertyForExpandableItems(displayType = 'block') {
        [...this.listItems]
            .slice(this.showCount)
            .forEach((listItem) => {
                listItem.style.display = displayType; // eslint-disable-line no-param-reassign
            });
    }

    bindListeners() {
        this.expandEl.addEventListener('click', (e) => {
            e.preventDefault();
            this.showExpandableItems();
            this.expandEl.style.display = 'none';
            this.collapseEl.style.display = 'block';
        });

        this.collapseEl.addEventListener('click', (e) => {
            e.preventDefault();
            this.hideExpandableItems();
            this.expandEl.style.display = 'block';
            this.collapseEl.style.display = 'none';
        });
    }
}

export function createCollapseLists() {
    const lists = document.querySelectorAll('.js-collapse-list');
    return [...lists].map(list => new CollapseList(list, list.dataset.showCount));
}

export default CollapseList;
