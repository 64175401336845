function autoSize(element) {
    // eslint-disable-next-line no-param-reassign
    element.style.height = 0;
    // eslint-disable-next-line no-param-reassign
    element.style.height = `${element.scrollHeight}px`;
}

function initializeAutoSizeElements() {
    setTimeout(() => {
        const elements = document.querySelectorAll('[autosize]');
        [...elements].forEach((el) => {
            autoSize(el);
            el.addEventListener('input', () => autoSize(el));
        });
    });
}

export { autoSize as default, initializeAutoSizeElements };
