class CourseDetail {
    constructor() {
        this.initializeAddParticipantButton();
    }

    initializeAddParticipantButton() {
        const button = document.querySelector('.js-add-participant');
        button.addEventListener('click', (e) => {
            e.preventDefault();
            this.addParticipant();
        });
    }

    addParticipant() {
        const wrapper = document.querySelector('.js-add-participant-wrapper');
        const template = document.querySelector('.js-add-participant-template').cloneNode(true);
        template.querySelectorAll('input, select').forEach((el) => {
            /* eslint-disable no-param-reassign */
            // Do not clear values of select elements
            // as they need a selected value by default.
            if (el instanceof HTMLInputElement) {
                el.value = '';
            }
            el.name = el.name.replace('[1]', `[${wrapper.childElementCount + 1}]`);
            /* eslint-enable no-param-reassign */
        });
        wrapper.appendChild(template);
    }
}

export default CourseDetail;
