import { DisableFormOnSubmit } from '../components/forms/disableFormOnSubmit';

class ChefDemoPerformance {
    constructor() {
        this.initializeToggleDemoCanceled();
        DisableFormOnSubmit('#confirm-demo-performance');
    }

    initializeToggleDemoCanceled() {
        const demoCanceledCheckBox = document.querySelector('.js-demo-canceled-checkbox').getElementsByTagName('input')[0];
        const demoCanceledFormWrapper = document.querySelector('.js-demo-canceled-wrapper');

        this.toggleDemoCanceled(demoCanceledCheckBox, demoCanceledFormWrapper);

        demoCanceledCheckBox.addEventListener('change', () => {
            this.toggleDemoCanceled(demoCanceledCheckBox, demoCanceledFormWrapper);
        });
    }

    toggleDemoCanceled(demoCanceledCheckBox, demoCanceledFormWrapper) {
        if (demoCanceledCheckBox.checked) {
            // eslint-disable-next-line no-param-reassign
            demoCanceledFormWrapper.style.display = 'block';
        } else {
            // eslint-disable-next-line no-param-reassign
            demoCanceledFormWrapper.style.display = 'none';
        }
    }
}

export default ChefDemoPerformance;
