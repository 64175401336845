/* eslint-disable no-undef */
// import lightGallery from 'lightgallery.js/dist/js/lightgallery';

require('lightgallery.js/lib/js/lightgallery');
require('lightgallery.js/lib/js/lg-utils');

export default class Gallery {
    constructor(element, options) {
        this.element = element;
        this.options = options;
    }

    init() {
        lightGallery(this.element);
    }
}
