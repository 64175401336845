import MicroModal from 'micromodal';

class Cart {
    constructor() {
        MicroModal.init({
            disableFocus: true,
            openClass: 'modal--open',
        });
    }
}

export default Cart;
