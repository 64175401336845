import Swiper from 'swiper';

export default class Slider {
    constructor(elementQuery, options) {
        this.elementQuery = elementQuery;
        this.options = options;
    }

    init() {
        // eslint-disable-next-line no-unused-vars
        return new Swiper(document.querySelector(this.elementQuery), this.options);
    }
}
