<template>
    <div class="toaster-list">
        <Toast
            v-for="toast of toasts"
            :key="toast.id"
            :title="toast.title"
            :body="toast.body"
            @close="closeToast(toast)"
        />
    </div>
</template>

<script>
import Toast from './Toast.vue';

export default {
    name: 'toast-list',

    components: {
        Toast,
    },

    data() {
        return {
            toasts: [],
        };
    },

    mounted() {
        window.livewire.on('quickOrderItemAdded', ({ title, body, itemId }) => {
            this.toasts.push({ title, body, id: itemId });
        });

        window.livewire.on('quickOrderPackAdded', ({ title, body, packId }) => {
            this.toasts.push({ title, body, id: packId });
        });
    },

    methods: {
        closeToast(closedToast) {
            this.toasts = this.toasts.filter(toast => toast !== closedToast);
        }
    }
}
</script>
