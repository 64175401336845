class Search {
    constructor() {
        this.wrapperEl = document.querySelector('.js-quick-order-search');
        this.inputEl = document.querySelector('.js-quick-order-search-input');
        this.dropdownEl = document.querySelector('.js-quick-order-search-dropdown');
        this.bindListeners();
        this.closeDropdown();
    }

    bindListeners() {
        this.handleFocusOnInput();
        this.handleEmptyInput();
        this.handleOutsideClick();
        this.handleEscapeKey();
        this.listenForLivewireEvents();
    }

    bindSearchResultListeners() {
        const packEls = document.querySelectorAll('.js-quick-order-add-pack');
        const itemEls = document.querySelectorAll('.js-quick-order-add-item');
        [...packEls].forEach((packEl) => { packEl.addEventListener('click', this.addPackFromElement); });
        [...itemEls].forEach((itemEl) => { itemEl.addEventListener('click', this.addItemFromElement); });
    }

    handleFocusOnInput() {
        this.inputEl.addEventListener('focus', (e) => {
            if (e.target.value === '') {
                return this.closeDropdown();
            }
            return this.openDropdown();
        });
    }

    handleEmptyInput() {
        this.inputEl.addEventListener('input', (e) => {
            if (e.target.value === '') {
                this.closeDropdown();
            }
        });
    }

    handleOutsideClick() {
        document.addEventListener('click', (e) => {
            if (!this.wrapperEl.contains(e.target)) {
                this.closeDropdown();
            }
        });
    }

    handleEscapeKey() {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.inputEl.blur();
                this.closeDropdown();
            }
        });
    }

    openDropdown() {
        this.dropdownEl.style.display = 'block';
    }

    closeDropdown() {
        this.dropdownEl.style.display = 'none';
    }

    addItem(itemNo, quantity = 1) {
        this.livewireComponent.call('addQuickOrderItem', itemNo, quantity);
    }

    addPack(packId, quantity = 1) {
        this.livewireComponent.call('addQuickOrderPack', packId, quantity);
    }

    addItemFromElement = (evt) => {
        this.addItem(evt.currentTarget.dataset.item);
    }

    addPackFromElement = (evt) => {
        this.addPack(evt.currentTarget.dataset.pack);
    }

    listenForLivewireEvents() {
        document.addEventListener('livewire:load', () => {
            this.livewireComponent = window.livewire.components.getComponentsByName('quick-order.search')[0]; // eslint-disable-line prefer-destructuring
        });

        window.livewire.on('quickOrderSearch', () => {
            this.bindSearchResultListeners();
            this.openDropdown();
        });

        window.livewire.on('quickOrderSearchCleared', () => {
            this.closeDropdown();
        });
    }

    removeEventListeners() {
        const packEls = document.querySelectorAll('.js-quick-order-add-pack');
        const itemEls = document.querySelectorAll('.js-quick-order-add-item');
        [...packEls].forEach((packEl) => { packEl.removeEventListener('click', this.addPackFromElement); });
        [...itemEls].forEach((itemEl) => { itemEl.removeEventListener('click', this.addItemFromElement); });
    }
}

export { Search as default, Search };
