class DisableFormOnSubmit {
    constructor(form) {
        if (form instanceof HTMLElement) {
            this.form = form;
        } else {
            this.form = document.querySelector(form);
        }

        this.form.onsubmit = e => this.disableSubmit(e);
    }

    submitForm() {
        this.disableSubmit();
    }

    disableSubmit() {
        const submitButtons = this.form.querySelectorAll('[type="submit"]');
        submitButtons.forEach((button) => {
            this.setButtonDisabled(button);
        });
    }

    setButtonDisabled(button) {
        const buttonIsDisabled = button.hasAttribute('disabled');
        if (!buttonIsDisabled) {
            button.setAttribute('disabled', '');
        } else if (buttonIsDisabled) {
            button.removeAttribute('disabled');
        }
        return this;
    }
}

const formInit = form => new DisableFormOnSubmit(form);

export { formInit as default, formInit as DisableFormOnSubmit };
