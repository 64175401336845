// Docs: https://docs.esign.eu/books/development/page/front-end-template#bkmrk-basicgooglemap

import googleMapsStyles from '../../constants/googleMapsStyles';

const defaultMapData = {
    holderId: 'map',
    searchBoxId: 'searchLocation',
    title: 'SelectLocation',
    lat: 50.967768,
    long: 3.735916,
};

export default class BasicGoogleMap {
    init(mapSettings = null) {
        const google = window.google || {};
        this.googleMaps = google.maps;
        this.baseUrl = window.baseUrl || '';

        // override default map data if param is set
        const mapData = mapSettings || defaultMapData;
        const holder = document.getElementById(mapData.holderId);
        const searchBoxInput = document.getElementById(mapData.searchBoxId);

        if (holder) {
            const map = this.addMap(holder, mapData.lat, mapData.long);
            if (searchBoxInput) {
                this.initSearchBox(map, mapData.searchBoxId);
            }
        }
    }

    addMap(holder, latitude, longitude) {
        const zoom = 8;
        const styledMap = new this.googleMaps.StyledMapType(googleMapsStyles, {
            name: 'Styled Map',
        });
        const mapCenter = new this.googleMaps.LatLng(latitude, longitude);
        const mapOptions = {
            zoom,
            panControl: true,
            zoomControl: true,
            scaleControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            minZoom: 2,
            scrollwheel: true,
            center: mapCenter,
            mapTypeId: this.googleMaps.MapTypeId.ROADMAP,
        };
        const map = new this.googleMaps.Map(holder, mapOptions);

        map.mapTypes.set('map_style', styledMap);
        map.setMapTypeId('map_style');

        this.googleMaps.event.addDomListener(window, 'resize', () => {
            map.setCenter(mapCenter);
        });

        return map;
    }

    initSearchBox(map, controlId) {
        // Create the search box and link it to the UI element.
        const input = document.getElementById(controlId);

        // remove enter submit in search
        input.addEventListener('keydown', (e) => {
            const { code } = e;
            if (code === 'Enter') {
                e.preventDefault();
                return false;
            }
            return true;
        });

        const searchBox = new this.googleMaps.places.SearchBox(input);

        this.markers = [];

        searchBox.addListener('places_changed', () => {
            this.setMapLocation(map, searchBox);
        });
    }

    // documentation: https://developers.google.com/maps/documentation/javascript/examples/places-searchbox?hl=en#maps_places_searchbox-javascript
    setMapLocation(map, searchBox) {
        const places = searchBox.getPlaces();

        if (places.length === 0) {
            return;
        }

        // Clear out the old markers.
        this.markers.forEach((marker) => {
            marker.setMap(null);
        });
        this.markers = [];

        // For each place, get the icon, name and location.
        const bounds = new this.googleMaps.LatLngBounds();

        places.forEach((place) => {
            if (!place.geometry || !place.geometry.location) {
                return;
            }

            const icon = {
                url: place.icon,
                size: new this.googleMaps.Size(71, 71),
                origin: new this.googleMaps.Point(0, 0),
                anchor: new this.googleMaps.Point(17, 34),
                scaledSize: new this.googleMaps.Size(25, 25),
            };

            // Create a marker for each place.
            this.markers.push(
                new this.googleMaps.Marker({
                    map,
                    icon,
                    title: place.name,
                    position: place.geometry.location,
                }),
            );
            if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
        });
        map.fitBounds(bounds);
    }
}
