import { Dropdown } from './Dropdown';

class SearchDropdown extends Dropdown {
    bindListeners() {
        this.handleTriggerFocus();
        this.handleOutsideClick();
        this.handleEscKey();
        this.handleEmptyState();
    }

    handleTriggerFocus() {
        this.triggerEl.addEventListener('focus', (e) => {
            if (e.target.value === '') {
                return this.close();
            }

            return this.open();
        });
    }

    handleEmptyState() {
        this.triggerEl.addEventListener('input', (e) => {
            if (e.target.value === '') {
                this.close();
            }
        });
    }
}

export { SearchDropdown as default, SearchDropdown };
