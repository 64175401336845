<template>
    <ul class="border border-light">
        <checkout-shipping-address-list-item
            v-for="(address, index) of addresses"
            :key="index"
            :addressName="address.name"
            :address="address"
            :selected="selectedIndex === index"
            @click.native="selectAddress(index, address)"
        ></checkout-shipping-address-list-item>
        <checkout-shipping-address-list-item
            :addressName="trans('checkout.custom_address')"
            :editable="true"
            :address="cartAddress"
            :selected="selectedIndex === addresses.length"
            @click.native="selectAddress(addresses.length, cartAddress)"
        ></checkout-shipping-address-list-item>
        <checkout-shipping-address-list-item
            :editable="false"
            :addressName="trans('checkout.pickup')"
            :address="pickupaddress"
            :selected="selectedIndex === addresses.length + 1"
            @click.native="selectAddress(addresses.length + 1, pickupaddress)"
        ></checkout-shipping-address-list-item>
    </ul>
</template>

<script>
import CheckoutShippingAddressListItem from './CheckoutShippingAddressListItem.vue';
import {mapActions} from "vuex";

export default {
    name: 'checkout-shipping-address-list',

    components: {
        CheckoutShippingAddressListItem,
    },

    props: {
        addresses: Array,
        cart: Object,
        pickupaddress: Object,
    },

    mounted() {
        if (this.cart.shipping_picked_at) {
            this.selectNewAddress();
        }

        this.selectAddress(0, this.addresses[0])
    },

    data() {
        return {
            selectedIndex: 0,
        };
    },


    computed: {
        cartAddress() {
            return {
                name: this.cart.shipping_name,
                address: this.cart.shipping_address,
                city: this.cart.shipping_city,
                post_code: this.cart.shipping_postal,
                country_region_code: this.cart.shipping_country,
                address_type: 'new',
            }
        },
    },

    methods: {
        selectNewAddress() {
            this.selectedIndex = this.addresses.length;
        },

        selectAddress(index, address) {
            this.selectedIndex = index;
            this.$store.dispatch('address/setSelectedAddress', address);
        },
    },
};
</script>
