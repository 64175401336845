class Dropdown {
    constructor(triggerEl, dropdownEl, dropdownOpenClass = 'c-dropdown--open') {
        this.triggerEl = triggerEl;
        this.dropdownEl = dropdownEl;
        this.dropdownOpenClass = dropdownOpenClass;
        if (this.requiredElementsAreMissing()) {
            return console.warn('Missing required elements to initialize dropdown'); // eslint-disable-line no-console
        }
        this.bindListeners();
    }

    requiredElementsAreMissing() {
        return !this.triggerEl || !this.dropdownEl;
    }

    bindListeners() {
        this.handleTriggerClick();
        this.handleOutsideClick();
        this.handleEscKey();
    }

    handleTriggerClick() {
        this.triggerEl.addEventListener('click', () => this.toggle());
    }

    handleOutsideClick() {
        document.addEventListener('click', (e) => {
            if (!this.dropdownEl.contains(e.target)) {
                this.close();
            }
        });
    }

    handleEscKey() {
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 27) {
                this.close();
                this.triggerEl.blur();
            }
        });
    }

    toggle() {
        this.dropdownEl.classList.toggle(this.dropdownOpenClass);
    }

    open() {
        this.dropdownEl.classList.add(this.dropdownOpenClass);
    }

    close() {
        this.dropdownEl.classList.remove(this.dropdownOpenClass);
    }
}

export { Dropdown as default, Dropdown };
