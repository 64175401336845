/* eslint-disable */
require('jquery/dist/jquery.js');
require('bootstrap/dist/js/bootstrap.bundle.js');
import Headroom from 'headroom.js/dist/headroom.js';
window.Headroom = Headroom;
import 'headroom.js/dist/jQuery.headroom.js';
require('masonry-layout/dist/masonry.pkgd.min.js');
require('lavalamp/js/jquery.lavalamp.min.js');
require('jquery-countdown/dist/jquery.countdown.min.js');
require('jquery-zoom/jquery.zoom.js');
import SmoothScroll from 'smooth-scroll';

import skrollr from 'skrollr';
import imagesLoaded from 'imagesloaded';

function initBootstrapTemplate() {
    'use strict';

    var fn = {
        // Launch Functions
        Launch: function() {
            fn.Header();
            fn.Masonry();
            fn.Apps();
        },

        // header
        Header: function() {
            $(document.body).headroom({
                tolerance: 10,
            });
        },

        // masonry
        Masonry: function() {
            var $grid = $('.masonry').masonry({
                itemSelector: '.masonry > *',
            });

            $grid.imagesLoaded().progress(function() {
                $grid.masonry('layout');
            });
        },

        // sidebar
        Sidebar: function() {
            var sidebar_open = $('.sidebar-open');
            var sidebar = $('.sidebar');
            var sidebar_close = $('.sidebar-close');
            sidebar_open.click(function() {
                if ($(this).hasClass('open')) {
                    $(this).removeClass('open');
                } else {
                    speaker.removeClass('open');
                    $(this).addClass('open');
                }
            });
        },

        // apps
        Apps: function() {
            // lavalamp
            $('.lavalamp').lavalamp({
                setOnClick: true,
                enableHover: false,
                margins: false,
                autoUpdate: true,
                duration: 200,
            });

            // steps
            $('.next-step').click(function() {
                $('.nav-steps > .active')
                    .next('.nav-link')
                    .trigger('click');
            });

            $('.prev-step').click(function() {
                $('.nav-steps > .active')
                    .prev('.nav-link')
                    .trigger('click');
            });

            // range slider
            $('.rangeslider').each(function() {
                var a = $(this),
                    min = a.data('min'),
                    max = a.data('max'),
                    from = a.data('from'),
                    to = a.data('to');

                var options = {
                    type: 'double',
                    min: min || 0,
                    max: max || 1000,
                    from: from || 200,
                    to: to || 800,
                    prefix: '$',
                };

                a.ionRangeSlider(options);
            });

            // counter
            $(document).ready(function() {
                $('.product-counter__btn--plus, .counter-plus').click(function() {
                    var fieldID = $(this).attr('field'),
                        fieldVal = parseInt($('input[name=' + fieldID + ']').val());

                    if (!isNaN(fieldVal)) {
                        $('input[name=' + fieldID + ']').val(fieldVal + 1);
                    } else {
                        $('input[name=' + fieldID + ']').val(0);
                    }
                });

                $('.product-counter__btn--minus, counter-minus').click(function() {
                    var fieldID = $(this).attr('field'),
                        fieldVal = parseInt($('input[name=' + fieldID + ']').val());

                    if (!isNaN(fieldVal) && fieldVal > 0) {
                        $('input[name=' + fieldID + ']').val(fieldVal - 1);
                    } else {
                        $('input[name=' + fieldID + ']').val(0);
                    }
                });
            });

            //  accordion with radio buttons
            $('.accordion-radio').on('click', function() {
                $(this)
                    .next('input')
                    .prop('checked', true);
            });

            // accordion active class
            $('.collapse').on('show.bs.collapse', function() {
                $(this)
                    .parent()
                    .addClass('active');
            });

            $('.collapse').on('hide.bs.collapse', function() {
                $(this)
                    .parent()
                    .removeClass('active');
            });

            // skrollr
            skrollr.init({
                forceHeight: false,
                mobileCheck: function() {
                    //hack - forces mobile version to be off
                    return false;
                },
            });

            // tooltips
            $('[data-toggle="tooltip"]').tooltip();

            // timer
            $('#timer').countdown('2020/01/01', function(event) {
                var $this = $(this).html(
                    event.strftime(
                        '' +
                            '<div>%D <span>days</span></div> ' +
                            '<div>%H <span>hours</span></div> ' +
                            '<div>%M <span>min</span></div> ' +
                            '<div>%S <span>sec</span></div>',
                    ),
                );
            });

            // smooth scroll
            $(function() {
                var scroll = new SmoothScroll('[data-scroll]');
            });

            // mobile menu open state
            $(document).ready(function() {
                $('.navbar-toggler').click(function() {
                    $('body').toggleClass('overlay-active');
                });
            });

            // zoom
            $('.zoom').zoom();
            // $('.zoom-click').zoom({ on:'click' });

            // sub menu
            $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
                if (
                    !$(this)
                        .next()
                        .hasClass('show')
                ) {
                    $(this)
                        .parents('.dropdown-menu')
                        .first()
                        .find('.show')
                        .removeClass('show');
                }
                var $subMenu = $(this).next('.dropdown-menu');
                $subMenu.toggleClass('show');

                $(this)
                    .parents('li.nav-item.dropdown.show')
                    .on('hidden.bs.dropdown', function(e) {
                        $('.dropdown-submenu .show').removeClass('show');
                    });

                return false;
            });
        },
    };

    $(document).ready(function() {
        fn.Launch();
    });
}

export { initBootstrapTemplate };
