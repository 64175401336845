<template>
    <div class="row gutter-1">
        <div class="form-group col-md-12">
            <label for="shipping_name">{{ trans('form_label.name') }}</label>
            <input
                type="text"
                name="shipping_name"
                class="form-control"
                id="shipping_name"
                :value="address.name"
                :readonly="!editable"
            />
        </div>
        <div class="form-group col-md-6">
            <label for="shipping_address">{{ trans('form_label.address') }}</label>
            <input
                type="text"
                name="shipping_address"
                class="form-control"
                id="shipping_address"
                :value="address.address"
                :readonly="!editable"
            />
        </div>
        <div class="form-group col-md-6">
            <label for="shipping_city">{{ trans('form_label.city') }}</label>
            <input
                type="text"
                name="shipping_city"
                class="form-control"
                id="shipping_city"
                :value="address.city"
                :readonly="!editable"
            />
        </div>
        <div class="form-group col-md-6">
            <label for="shipping_postal">{{ trans('form_label.zip') }}</label>
            <input
                type="text"
                name="shipping_postal"
                class="form-control"
                id="shipping_postal"
                :value="address.post_code"
                :readonly="!editable"
            />
        </div>
        <div class="form-group col-md-6">
            <label for="shipping_country">{{ trans('form_label.country') }}</label>
            <select
                class="form-control"
                id="shipping_country"
                name="shipping_country"
                :readonly="!editable"
            >
                <option
                    v-for="(country, index) of countries"
                    :key="index"
                    :value="country.code"
                    :selected="address.country_region_code == country.code"
                >{{ country.name }}</option>
            </select>
        </div>
        <div class="form-group col-md-12" v-if="editable">
            <label for="phone">{{ trans('form_label.phone') }}</label>
            <input
                type="text"
                name="phone"
                class="form-control"
                id="phone"
            />
        </div>
        <div class="form-group col-12" v-if="editable">
            <div class="form-group">
                <input
                    class="mr-1"
                    type="checkbox"
                    value="1"
                    id="create_address"
                    name="create_address"
                />
                <label for="create_address">
                    {{ trans('checkout-shipping.create_address') }}
                </label>
            </div>
        </div>
        <input type="hidden" name="address_type" :value="address.address_type">
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    name: 'address-form-fields',

    props: {
        address: {
            type: Object,
            required: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapState('address', ['countries']),
    },

    methods: {
        ...mapActions('address', ['fetchCountries']),
    },

    mounted() {
        this.fetchCountries();
    }
};
</script>
