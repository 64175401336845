import Vue from 'vue';
import 'livewire-vue';
import Axios from 'axios';
import { Navigation, Pagination, Thumbs } from 'swiper';
import { initBootstrapTemplate } from './bootstrap-template';
import CheckoutShippingAddressList from './components/checkout/CheckoutShippingAddressList.vue';
import OrderSummary from './components/checkout/OrderSummary.vue';
import ToastList from './components/toast/ToastList.vue';
import Gallery from './components/gallery/Gallery';
import Slider from './components/sliders/Slider';
import store from './store';
import { trans } from './utils/translations';
import QuickOrder from './pages/QuickOrder';
import WarrantyCreate from './pages/WarrantyCreate';
import WarrantyOverview from './pages/WarrantyOverview';
import DeliveryDate from './pages/DeliveryDate';
import Cart from './pages/Cart';
import CourseDetail from './pages/CourseDetail';
import BookAChef from './pages/BookAChef';
import ChefDemoPerformance from './pages/ChefDemoPerformance';
import ProductsOverview from './pages/ProductsOverview';
import ArchiveShow from './pages/ArchiveShow';
import Courses from './pages/Courses';
import { createCollapseLists } from './components/lists/CollapseList';
import { intializeCounters } from './components/inputs/Counter';
import { initializeAutoSizeElements } from './utils/autoSize';
import { initializeTACModal } from './components/modals/termsAndConditions';

initBootstrapTemplate();

window.Vue = Vue;
Vue.mixin({
    methods: {
        route: window.route,
        trans,
    },
});

new Vue({ // eslint-disable-line no-new
    el: '#app',
    store,
    components: {
        CheckoutShippingAddressList,
        OrderSummary,
        ToastList,
    },
});

// Default headers for http requests
Axios.defaults.headers.common['X-CSRF-TOKEN'] = window.laravel.CSRF_TOKEN;
Axios.defaults.headers['Accept'] = 'application/json'; // eslint-disable-line dot-notation
Axios.defaults.headers['Content-Type'] = 'application/json';

const imageGallery = new Gallery(document.getElementById('lightgallery'), {});
imageGallery.init();

const galleryThumbsSlider = new Slider('.gallery-thumbs', {
    direction: 'horizontal',
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    centeredSlides: true,
    slidesPerView: 4,
    spaceBetween: 10,
    height: 'auto',
    breakpoints: {
        1200: {
            direction: 'vertical',
        },
    },
});

const thumbSlider = galleryThumbsSlider.init();

const galleryMainSlider = new Slider('.gallery-main', {
    modules: [Navigation, Thumbs],
    direction: 'horizontal',
    preloadImages: true,
    watchOverflow: true,
    updateOnImagesReady: true,
    slidesPerView: 1,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    thumbs: {
        swiper: thumbSlider,
    },
});
galleryMainSlider.init();

const swiperContainerActions = new Slider('.swiper-container--actions', {
    modules: [Navigation, Pagination],
    direction: 'horizontal',
    slidesPerView: 1,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
});
swiperContainerActions.init();

// Page specific classes
const pages = {
    QuickOrder,
    WarrantyCreate,
    WarrantyOverview,
    Cart,
    CourseDetail,
    DeliveryDate,
    BookAChef,
    ChefDemoPerformance,
    ProductsOverview,
    ArchiveShow,
    Courses,
};

const currentPage = document.documentElement.getAttribute('data-page');
if (currentPage) {
    const pageClassName = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);

    if (pageClassName !== '' && typeof pages[pageClassName] === 'function') {
        new pages[pageClassName](); // eslint-disable-line no-new
    }
}

createCollapseLists();
intializeCounters();
initializeAutoSizeElements();
initializeTACModal();
