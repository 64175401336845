import axios from 'axios';

/* eslint-disable no-param-reassign */
export default {
    namespaced: true,

    state: {
        selectedAddress: {},
        countries: [],
    },

    mutations: {
        SET_SELECTED_ADDRESS(state, address) {
            state.selectedAddress = address;
        },
        SET_COUNTRIES(state, countries) {
            state.countries = countries;
        },
    },

    actions: {
        setSelectedAddress({ commit }, address) {
            commit('SET_SELECTED_ADDRESS', address);
        },
        fetchCountries({ commit }) {
            axios
                .get(window.route('json.countries.index'))
                .then(res => commit('SET_COUNTRIES', res.data.data));
        },
    },

    getters: {
        selectedAddress(state) {
            return state.selectedAddress;
        },
    },

};
/* eslint-enable no-param-reassign */
