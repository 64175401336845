class Counter {
    constructor(
        counterElement,
        inputElementQuery = '.js-counter-input',
        incrementElementQuery = '.js-counter-increment',
        decrementElementQuery = '.js-counter-decrement',
    ) {
        this.counterElement = counterElement;
        this.inputElement = this.counterElement.querySelector(inputElementQuery);
        this.incrementElement = this.counterElement.querySelector(incrementElementQuery);
        this.decrementElement = this.counterElement.querySelector(decrementElementQuery);
        this.bindListeners();
    }

    bindListeners() {
        this.incrementElement.addEventListener('click', (e) => {
            e.preventDefault();
            this.increment();
        });
        this.decrementElement.addEventListener('click', (e) => {
            e.preventDefault();
            this.decrement();
        });
    }

    increment() {
        this.inputElement.stepUp();
    }

    decrement() {
        this.inputElement.stepDown();
    }
}

function intializeCounters() {
    const counters = document.querySelectorAll('.js-counter');
    [...counters].map(el => new Counter(el));
}

export { Counter as default, Counter, intializeCounters };
