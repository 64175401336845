export function trans(term) {
    try {
        const translation = window.translations.find(item => item.term === term);
        return translation.value || term;
    } catch (err) {
        return term;
    }
}

export default trans;
