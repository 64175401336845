import Cookies from 'js-cookie';
import MicroModal from 'micromodal';

const COOKIE_ID = 'terms-and-condition-modal-seen';

function initializeTACModal() {
    if (!Cookies.get(COOKIE_ID) && document.getElementById('modal-terms-and-conditions')) {
        MicroModal.show('modal-terms-and-conditions', {
            openClass: 'modal--open',
            onClose: () => {
                Cookies.set(COOKIE_ID, true, { expires: 1 });
            },
        });
    }
}

export { initializeTACModal as default, initializeTACModal };
